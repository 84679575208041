* {
  font-family: 'Mitr', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cart-item > img {
  align-items: center;
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.information-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}

.slide > div {
  display: flex;

  align-items: center;
  justify-content: center;
  background-size: contain;
  height: 640px;
  background-repeat: no-repeat;
  background-position: center center;
}

.toy-space-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 5px;
  /* border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px 8px #888888; */
}

.slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.slide-container {
  width: 100%;
  display: 'flex';
  margin: auto;
  position: relative;
}
